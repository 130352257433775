// 组件存储
export default class WidgetStorage {
    constructor(p="") {
        this.storage = window.localStorage;
        this.pref = "WIDGET:" + p;
        this.store = {};
        const {storage, pref, store} = this;
        for (let i = 0; i < storage.length; i++) {
            let key = storage.key(i);
            if(key.startsWith(pref)) {
                let k = key.substring(pref.length);
                store[k] = this.get(k);
            }
        }
    }

    clear () {
        const {store, storage, pref} = this;
        Object.keys(store).forEach(key => {
            storage.removeItem(pref + key);
        });
        this.store = {};
    }

    remove (key) {
        const {store, storage, pref} = this;
        key = pref + key;
        let remove = store[key];
        storage.removeItem(key);
        delete store[key];
        return remove;
    }

    set (key, val = "") {
        const {store, storage, pref} = this;
        if(!val) {
            val = key;
            key = "";
        }
        val = typeof val === 'object' ? JSON.stringify(val) : val;
        key = pref + key;
        store[key] = val;
        storage.setItem(key, val);
    }

    get (key = "") {
        const {pref, storage, store} = this;
        if (!store[key]) {
            let val = storage.getItem(pref + key);
            try {
                //如果是json 反序列化成对象返回
                if(typeof val === 'string' && (val.startsWith("{") || val.startsWith("["))) {
                    store[key] = JSON.parse(val)
                } else {
                    store[key] = val;
                }
            } catch (e) {
                store[key] = val;
            }
        }
        return store[key];
    }
}
