import WidgetStorage from './lib/WidgetStorage.js';

const localStorage = window.localStorage;
const $ = window.jQuery;
const noop = $.noop

class AsyncLogin {
    constructor() {
        this.data = {username: '', password: '', remember: false};
        this.events = {success: noop, fail: noop, onCodeSuccess: noop, beforeSubmit: noop, complete: noop};
        Object.defineProperties(this,{data: {writable: false,enumerable:false},events: {writable: false, enumerable: false}})
        this.form = $('form.async-login').attr("onsubmit", "return false;");
        this.form.find(":input").on("keyup", e => {
            if (e.which === 13) {
                this.form.find('.async-login-submit').click();
            }
        });
        this.invalid = !this.form.length;
        this.storage = new WidgetStorage("ASYNC-LOGIN");
        this.invalid && console.debug('Form use default');
    }

    loginWithUser() {
        let {form, invalid, storage} = this;
        if (invalid) return this;
        const getData = () => {
            let self = this;
            form.find(":input").each(function () {
                let name = $(this).attr("name");
                if (!name) return;
                if (name === 'remember') {
                    self.data.remember = $(this).is(":checked");
                } else {
                    self.data[name] = $(this).val();
                }
            });
            return self.data;
        };

        let $submit = form.find('.async-login-submit');
        if (!$submit.length) {
            console.error('Submit button .async-login-submit not found');
            return this;
        }

        let submitting = false;

        const verificationCode = ({twiceCheck}) => {
            if (!twiceCheck) return true;
            $(".async-verification-code").show().find(":input").attr("name", "code");
            return false;
        };

        $submit.click(() => {
            if (submitting) return;
            submitting = true;
            let data;
            try {
                data = getData()
            } catch (e) {
                submitting = false;
                return;
            }
            const {username, password} = data;
            if (!username || !password) {
                this.events.fail.call(form, '用户名或密码不能为空！', data);
                submitting = false;
                return false;
            }
            let uri = form.attr('action') || '/login';
            this.events.beforeSubmit.call(form, data);
            $.post(uri, form.serialize()).done(({success, describe, data, code}) => {
                if (success) {
                    if (data && !verificationCode(data)) {
                        submitting = false;
                        return;
                    }
                    this.events.success.call(form, data);
                } else {
                    this.events.fail.call(form, describe, code);
                    submitting = false;
                }
                this.events.complete.call(form, success, {data, describe, code})
            });
        });
        return this;
    }

    loginWithCode () {
        let {form, invalid, storage, events} = this;
        if (invalid) return this;
        let $getCodeBtn = form.find('.get-verification-code').addClass('disable');
        let defaultLabel = $getCodeBtn.text();
        let countDown = storage.get('count-down');
        $.extend($getCodeBtn, {
            disable: function () {
                $(this).addClass("disable");
            },
            enable: function () {
                $(this).removeClass('disable');
            },
            defaultLabel: function () {
                this.enable();
                $(this).text(defaultLabel);
            },
            countDownLabel: function () {
                this.disable();
                clearInterval(this.intervalId);
                let countDown = storage.get('count-down') || 60;
                this.intervalId = setInterval(function () {
                    countDown --;
                    if (countDown > 0) {
                        $getCodeBtn.text(`（${countDown}s）后重新获取`);
                        storage.set('count-down', countDown);
                    } else {
                        storage.remove('count-down');
                        $getCodeBtn.defaultLabel();
                    };
                }, 1000);
            }
        });
        if (countDown) {
            $getCodeBtn.countDownLabel();
        }
        $getCodeBtn.disable();
        let pending = false;
        const isPhone = val => /^1\d{10}$/.test(val);
        const isEmail = val => /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(val);
        const isEmailOrPhone = val => isPhone(val) || isEmail(val);
        let $phoneOrEmail = form.find(":input[name=phoneOrEmail]");
        $phoneOrEmail.on("keyup", function () {
            let val = $(this).val();
            if (isEmailOrPhone(val)) {
                $getCodeBtn.enable();
            } else $getCodeBtn.disable();
        });

        $getCodeBtn.click(function (e) {
            if (pending) return false;
            let phoneOrEmail = $phoneOrEmail.val();
            if (!isEmailOrPhone(phoneOrEmail)) {
                events.fail.call(form, '邮箱或者手机号格式错误！');
                return false;
            }
            pending = true;
            $getCodeBtn.disable();
            let data = {};
            if (isPhone(phoneOrEmail)) {
                data.phone = phoneOrEmail;
            } else {
                data.email = phoneOrEmail;
            }
            $.get('dyauth/code', data).done(({success, describe}) => {
                if (success) {
                    $getCodeBtn.countDownLabel();
                    events.onCodeSuccess.call(form, phoneOrEmail);
                } else {
                    events.fail.call(form, describe, phoneOrEmail);
                    pending = false;
                }
            });
        });

        let $submit = form.find('.async-login-submit');
        if (!$submit.length) {
            console.error('Submit button .async-login-submit not found');
            return this;
        }
        let submitting = false;
        $submit.click(function (e) {
            if (submitting) return false;
            submitting = true;
            let phoneOrEmail = $phoneOrEmail.val();
            let data = {code: form.find(':input[name=code]').val()};
            if (!data.code) {
                events.fail.call(form, '请填写验证码！');
                submitting = false;
                return false;
            }
            if (isPhone(phoneOrEmail)) {
                data.phone = phoneOrEmail;
            } else {
                data.email = phoneOrEmail;
            }
            this.events.beforeSubmit.call(form, data);
            $.post(form.attr("action") || 'login', data, ({success, describe, data, code}) => {
                if (success) {
                    events.success.call(form, data);
                } else {
                    events.fail.call(form, describe, code);
                    submitting = false;
                }
                events.complete.call(form, success, {data, describe, code})
            })
        });
        return this;
    }

    on(type, fn) {
        if (typeof type === "object") {
            for (let prop in type) {
                this.on(prop, type[prop])
            }
            return this;
        }
        this.events[type] = fn || noop;
        return this;
    }
}

const loginAsync = events => {
    if (typeof events === 'string') {
        let index = events;
        events = {
            success: function () {
                location.href = index;
            }
        };
    }
    let withCode = "onCodeSuccess" in events;
    let login = new AsyncLogin();
    let tipper = events['prompt'] || layer.msg;
    if (tipper) {
        events.fail = describe => tipper(describe);
        events.beforeSubmit = () => tipper('登陆中，请稍后。。。', {time: 99999, shade: 0.3});
    }
    login.on(events)[withCode ? 'loginWithCode' : 'loginWithUser']();
};

window.login = (resolve, reject, onCodeSuccess) => {
    loginAsync({success:resolve, fail:reject, onCodeSuccess})
};

window.loginAsync = loginAsync;
